import { useCallback, useEffect, useState } from 'react';
import hasCustomPaymentCompletePage from 'src/common/components/payment/utils/hasCustomPaymentCompletePage';
import { isLarge, isXLarge, isXXLarge } from 'src/common/utils/breakpoints';

export default function useSideBar(
  breakpoint,
  isMobile,
  query,
  pathname,
  { isLanding, hideLeft, hideRight, hideRightSideTillXxl, matchUrl, isDiscord },
) {
  const isNotLandingOrMobile = !(isLanding || isMobile);
  const isLeftSideDocked =
    isNotLandingOrMobile && !isDiscord && isLarge(breakpoint);
  // Right side serves on xxlarge profile pages and xlarge other pages (not on landing page)
  const isRightSideOpen =
    isNotLandingOrMobile &&
    !isDiscord &&
    !hideRight &&
    (hideRightSideTillXxl ? isXXLarge(breakpoint) : isXLarge(breakpoint));
  const [isLeftSideOpen, setIsLeftSideOpen] = useState(isLeftSideDocked);

  const shouldHideLeftSide = hasCustomPaymentCompletePage(query, pathname);
  const hideLeftSide = hideLeft || shouldHideLeftSide;

  useEffect(() => {
    setIsLeftSideOpen(isLeftSideDocked);
  }, [matchUrl, isLeftSideDocked]);

  const handleLeftSideToggle = useCallback(() => {
    setIsLeftSideOpen((value) => !value);
  }, []);

  return {
    isLeftSideDocked,
    isLeftSideOpen,
    isRightSideOpen,
    hideLeftSide,
    handleLeftSideToggle,
  };
}
