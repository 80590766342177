import hasCustomPaymentCompletePage from 'src/common/components/payment/utils/hasCustomPaymentCompletePage';
import viewTypes from '../constants/auth/viewTypes';
import { AUTHORIZE_PATH, PIN_CODE_AUTH_PATHS } from '../constants/paths';
import { hasPairAuthFlowParam } from './auth/partnerAuthFlowHelpers';

/**
 * @param query object
 * @param pathname string i.e. /authorize/
 * @param viewType string i.e. si, su, etc.
 * @returns boolean - true === should hide header
 */
export default function hideHeaderHelper(
  query,
  pathname,
  viewType,
  isMobile,
  routeProps,
) {
  const inPairAuthFlow = hasPairAuthFlowParam(query);
  const isSignInSignUpView = [viewTypes.signIn, viewTypes.signUp].includes(
    viewType,
  );

  return (
    inPairAuthFlow ||
    PIN_CODE_AUTH_PATHS.includes(pathname) ||
    (pathname === AUTHORIZE_PATH && isSignInSignUpView) ||
    routeProps.noHeader ||
    (isMobile && routeProps.noHeaderOnMobile) ||
    hasCustomPaymentCompletePage(query, pathname)
  );
}
