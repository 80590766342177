import { PAYMENT_COMPLETE_PATH } from 'src/common/constants/paths';
import { getSourceParam } from 'src/common/utils/queryParams';

const partnerSourcesAllowlist = {
  tesla: true,
  vizio_upsell: true,
};

/**
 * @param query object
 * @param pathname string i.e. /paymentcomplete/
 * @returns boolean
 */
export default function hasCustomPaymentCompletePage(query, pathname) {
  return !!(
    pathname === PAYMENT_COMPLETE_PATH &&
    partnerSourcesAllowlist[getSourceParam(query)]
  );
}
